import React from 'react'
import styled from '@emotion/styled'
import capitalize from 'lodash/capitalize'
import Layout from '../../components/Layout'
import VerticalArticlesList from '../common/page_components/VerticalArticlesList'
import { getQueryFields } from '../../utils/helpers'

const PageContainer = styled.div`
  min-height: 540px;
  padding-bottom: 2.5rem;
`

const TagPage = ({ posts, tagName }) => {
  const capitalizedTagName = capitalize(tagName)
  const articlesMapped = getQueryFields(posts)

  return (
    <Layout removeFooter adsense>
      <PageContainer>
        <VerticalArticlesList
          articles={articlesMapped}
          title={`${capitalizedTagName} Articles`}
        />
      </PageContainer>
    </Layout>
  )
}
export default TagPage
