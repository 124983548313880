import React from 'react'
import { graphql } from 'gatsby'
import TagPage from '../components/tagPage/TagPage'

class TagRoute extends React.Component {
  render () {
    const posts = this.props.data.allMarkdownRemark.nodes
    const tag = this.props.pageContext.tag

    return <TagPage posts={posts} tagName={tag} />
  }
}

export default TagRoute

export const tagPageQuery = graphql`
  query TagPage($tag: String) {
    allMarkdownRemark(
      limit: 1000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { tags: { in: [$tag] } } }
    ) {
      nodes {
        fields {
          slug
          thumbnail {
            ...TooSmallThumbnail
          }
        }
      frontmatter {
        title
        snippet
      }
    }
  }
}
`
